import { Menu, MenuItem } from "@mui/material"
import FeedbackModal from "./FeedbackModal"
import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { getLoggedInUserInfo } from "constant/helpersUtility"
import {
    setOpenLogout,
    setProfileButton,
    setText,
    setButton
} from "redux/slices/navSlice"
import { useLocation, useNavigate } from "react-router-dom"
import { useDisclosure } from "hooks/useDisclosure"

const ProfileMenu = ({ isOpen, close, anchorEl }) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [user] = useState(getLoggedInUserInfo)
    const [profileMenus] = useState(() => {
        return getProfileMenu(user?.userTypeId?.description) || []
    })
    const {
        isOpen: isFeedbackModalOpen,
        open: openFeedbackModal,
        close: closeFeedbackModal
    } = useDisclosure()

    const roleDesc = user?.userTypeId?.description

    useEffect(() => {
        const profMenu = getProfileMenu(roleDesc) || []
        for (const element of profMenu) {
            if (location.pathname.includes(element.path)) {
                dispatch(setButton(element))
                dispatch(setText(element.text))
            }
        }
    }, [location.pathname, roleDesc, dispatch])

    const handleProfileMenuItemClick = (navInfo) => {
        close()
        if (navInfo.text === "Feedback") {
            openFeedbackModal()
            return
        }

        dispatch(setProfileButton(navInfo))
        if (navInfo.text === "Logout") {
            dispatch(setOpenLogout())
        } else {
            navigate(navInfo.path)
        }
    }

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={isOpen}
                onClose={close}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: 0,
                            mr: 0
                        },
                        "& .MuiButtonBase-root:hover": {
                            backgroundColor: "#693BE1",
                            color: "white"
                        }
                    }
                }}
                transformOrigin={{ horizontal: "left", vertical: "bottom" }}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
            >
                {profileMenus.map((data, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            handleProfileMenuItemClick(data)
                        }}
                    >
                        {data.text}
                    </MenuItem>
                ))}
            </Menu>
            {isFeedbackModalOpen && (
                <FeedbackModal isOpen={true} close={closeFeedbackModal} />
            )}
        </>
    )
}
const getProfileMenu = (roleDesc) => {
    const commonMenu = [
        {
            text: "Change Password",
            path: "/changePassword"
        },
        {
            text: "Change Currency",
            path: "/changeCurrency"
        }
    ]

    const logoutMenu = {
        text: "Logout",
        path: "/logout"
    }

    let feedbackMenu = []
    let profileLink = "/profile"

    switch (roleDesc) {
        case "Client Head":
            profileLink = "/hniprofile"
            feedbackMenu = [
                {
                    text: "Feedback"
                }
            ]
            break
        case "Client Family Member":
            profileLink = "/memberProfile"
            feedbackMenu = [
                {
                    text: "Feedback"
                }
            ]
            break
        case "Investor":
            feedbackMenu = [
                {
                    text: "Feedback"
                }
            ]
            profileLink = "/investor-profile"
            break
        default:
    }

    return [
        {
            text: "My Profile",
            path: profileLink
        },
        ...commonMenu,
        ...feedbackMenu,
        logoutMenu
    ]
}
export default ProfileMenu
