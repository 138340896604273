import { Box, Typography, Stack } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { Formik, Form } from "formik"
import { PageLoader, TextFieldWrapper } from "components/common/Ui/Form"
import { useLightInvestorReg } from "./useLightInvestorReg"
import logo from "theme/images/NewSidebarIcons/logoKairosV1.svg"

const LightInvestorRegistration = () => {
    const {
        handleSubmit,
        defaultValues,
        validationSchema,
        fields,
        isLoading,
        loaderText
    } = useLightInvestorReg()

    return (
        <>
            <PageLoader isLoading={isLoading} loaderText={loaderText} />
            <Box
                sx={{
                    px: 5,
                    pt: 2,
                    pb: 4,
                    backgroundColor: "white",
                    borderRadius: 3
                }}
            >
                <Header />
                <Formik
                    initialValues={defaultValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form>
                        <Stack
                            sx={{
                                flexDirection: {
                                    xs: "column"
                                }
                            }}
                            mb={3}
                            gap={3}
                        >
                            <TextFieldWrapper
                                type="text"
                                name="firstName"
                                placeholder="Enter first name"
                                label="First Name"
                                required={true}
                            />
                            <TextFieldWrapper
                                type="text"
                                name="lastName"
                                placeholder="Enter last name"
                                label="Last Name"
                                required={true}
                            />
                        </Stack>
                        {fields.map(({ name, type, label, placeholder }) => (
                            <Box mb={3}>
                                <TextFieldWrapper
                                    name={name}
                                    type={type}
                                    placeholder={placeholder}
                                    label={label}
                                    required={true}
                                />
                            </Box>
                        ))}
                        <LoadingButton
                            type="submit"
                            size="large"
                            variant="contained"
                            color="brand"
                            fullWidth
                        >
                            Sign up
                        </LoadingButton>
                    </Form>
                </Formik>
            </Box>
        </>
    )
}

const Header = () => {
    return (
        <Stack direction="column" alignItems="center">
            <img
                style={{
                    width: "48px",
                    height: "48px",
                    objectFit: "cover"
                }}
                src={logo}
                alt="logoIcon"
            />
            <Typography variant="h2" fontWeight={500}>
                Register on Kairos
            </Typography>
            <Typography variant="body1" mb={4}>
                Join the Kairos Network now.
            </Typography>
        </Stack>
    )
}
export default LightInvestorRegistration
