import { createSlice } from "@reduxjs/toolkit"

const defaultData = {
    profilePhotoUrl: "",
    firstName: "",
    lastName: "",
    email: "",
    status: "",
    reason: "",
    btnText: "Request Management Approval",
    isReadOnly: false,
    dropdowns: {
        identityOptions: [],
        addressOptions: [],
        financialGoalOptions: [],
        financialProductOptions: [],
        riskAppetiteOptions: [],
        industryPreferenceOptions: [],
        rmAuthorizedOptions: [],
        sourceOfWealthOptions: []
    },
    personalDetails: {
        salutation: "",
        firstName: "",
        lastName: "",
        address1: "",
        address2: "",
        address3: "",
        countryId: "",
        gender: "",
        stateId: "",
        cityId: "",
        postalCode: "",
        dob: "",
        highestLevelOfEducation: "",
        profilePhotoUrl: "",
        mobileCountryCode: "",
        mobile: "",
        email: "",
        secondaryEmail: "",
        baseLocationId: "",
        originId: "",
        altMobile: "",
        countryCodeAltMobile: ""
    },
    professionalDetails: {
        professionType: [],
        industry: [],
        domainSector: [],
        turnover: ""
    },
    proofOfIdentity: {
        expiryDate: "",
        idNumber: "",
        proofOfIdentitySelect: "",
        name: "",
        url: ""
    },
    proofOfAddress: {
        proofOfAddressSelect: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        name: "",
        url: ""
    },
    proofOfTaxResidency: {
        countryResidency: "",
        name: "",
        url: ""
    },
    otherDocuments: [],
    proofOfWealth: [],
    sourceOfWealth: [],
    interestDetails: {
        financialProduct: [],
        lifestyleProduct: [],
        financialService: [],
        lifestyleService: [],
        personality: [],
        currentMemberships: [],
        previousVacations: []
    },
    financialDetails: {
        netWorth: "",
        sourceOfWealth: [],
        investibleWealth: "",
        riskAppetite: "",
        industryPreference: [],
        financialGoals: [],
        joiningFee: "",
        subscriptionFee: "",
        isRmAuthorized: "",
        presentAssetAllocation: []
    },
    missingPersonalFields: [],
    missingFinancialFields: [],
    missingKycFields: []
}

// ** Helper
const setPersonalMissingFields = (state, data) => {
    const missingFields = []
    if (!Boolean(data.firstName)) {
        missingFields.push("First Name")
    }
    if (!Boolean(data.lastName)) {
        missingFields.push("Last name")
    }
    if (!Boolean(data.dob)) {
        missingFields.push("Date of birth")
    }
    if (!Boolean(data.gender?.id)) {
        missingFields.push("Gender")
    }
    if (!Boolean(data.highestLevelOfEducation?.id)) {
        missingFields.push("Education")
    }
    if (!Boolean(data.email)) {
        missingFields.push("Email")
    }
    if (!Boolean(data.mobile)) {
        missingFields.push("Mobile")
    }
    if (!Boolean(data.address1)) {
        missingFields.push("Address 1")
    }
    if (!Boolean(data.baseLocationId?.id)) {
        missingFields.push("Base location")
    }
    if (!Boolean(data.countryId?.id)) {
        missingFields.push("Country")
    }
    if (!Boolean(data.postalCode)) {
        missingFields.push("Postal code")
    }

    state.missingPersonalFields = missingFields
}

const prospectProfileSlice = createSlice({
    name: "prospectProfile",
    initialState: defaultData,
    reducers: {
        setProspectKycDropdowns: (state, action) => {
            state.dropdowns.identityOptions = action.payload.identityOptions
            state.dropdowns.addressOptions = action.payload.addressOptions
        },
        setPersonalDetails: (state, action) => {
            let dob = ""
            if (action.payload.dob) {
                const [day, month, year] = action.payload.dob.split("-")
                dob = `${year}-${month}-${day}`
            }
            const profileData = action.payload

            setPersonalMissingFields(state, profileData)

            const payload = {
                salutation: profileData.salutation?.id || "",
                countryId: profileData.countryId?.id || "",
                stateId: profileData.stateId?.id || "",
                cityId: profileData.cityId?.id || "",
                dob,
                highestLevelOfEducation:
                    profileData.highestLevelOfEducation?.id || "",
                gender: profileData.gender?.id || "",
                baseLocationId: profileData.baseLocationId?.id || "",
                mobileCountryCode: profileData.mobileCountryCode?.id || "",
                countryCodeAltMobile:
                    profileData.countryCodeAltMobile?.id || "",
                firstName: profileData.firstName || "",
                lastName: profileData.lastName || "",
                address1: profileData.address1 || "",
                address2: profileData.address2 || "",
                address3: profileData.address3 || "",
                postalCode: profileData.postalCode || "",
                profilePhotoUrl: profileData.profilePhotoUrl || "",
                mobile: profileData.mobile || "",
                email: profileData.email || "",
                secondaryEmail: profileData.secondaryEmail || "",
                altMobile: profileData.altMobile || "",
                originId: profileData.originId || ""
            }
            state.personalDetails = payload
            state.profilePhotoUrl = profileData.profilePhotoUrl || ""
            state.firstName = profileData.firstName || ""
            state.lastName = profileData.lastName || ""
            state.email = profileData.email || ""
            state.reason = profileData.reason

            // set btn status
            switch (profileData?.status?.toUpperCase()) {
                case "DRAFT":
                    state.btnText = "Request Management Approval"
                    state.isReadOnly = false
                    state.status = "Draft"
                    break
                case "PENDING FOR MANAGEMENT APPROVAL":
                case "WFA":
                    state.btnText = "Pending Management Approval"
                    state.isReadOnly = true
                    state.status = "Pending"
                    break
                case "REJECTED":
                    state.status = "Rejected"
                    state.btnText = "Request Management Approval"
                    state.isReadOnly = false
                    break
                default:
                    state.status = ""
                    state.isReadOnly = false
            }
        },
        setProfessionalDetails: (state, action) => {
            const payload = {
                professionType:
                    action.payload.professionType?.map((prof) => prof.id) || [],
                industry: action.payload.industry?.map((ind) => ind.id) || [],
                domainSector:
                    action.payload.domainSector?.map((dom) => dom.id) || [],
                turnover: action.payload.turnover || ""
            }
            state.professionalDetails = payload
        },
        setProspectKycDetails: (state, action) => {
            const {
                proofOfIdentity,
                proofOfAddress,
                proofOfTaxResidency,
                proofOfWealth,
                sourceOfWealth,
                otherDocuments
            } = action.payload

            const ipayload = {
                expiryDate: proofOfIdentity?.expiryDate || "",
                idNumber: proofOfIdentity?.idNumber || "",
                proofOfIdentitySelect:
                    proofOfIdentity?.proofOfIdentitySelect?.id || "",
                name: proofOfIdentity?.name || "",
                url: proofOfIdentity?.url || ""
            }
            state.proofOfIdentity = ipayload

            const apayload = {
                proofOfAddressSelect:
                    proofOfAddress?.proofOfAddressSelect?.id || "",
                address1: proofOfAddress?.address1 || "",
                address2: proofOfAddress?.address2 || "",
                city: proofOfAddress?.city || "",
                state: proofOfAddress?.state || "",
                zipcode: proofOfAddress?.zipcode || "",
                name: proofOfAddress?.name || "",
                url: proofOfAddress?.url || ""
            }
            state.proofOfAddress = apayload

            const tpayload = {
                name: proofOfTaxResidency?.name || "",
                url: proofOfTaxResidency?.url || "",
                countryResidency:
                    proofOfTaxResidency?.countryResidency?.id || ""
            }

            const missingFields = []
            if (!Boolean(proofOfIdentity)) {
                missingFields.push("Proof of Identity")
            }
            if (!Boolean(proofOfAddress)) {
                missingFields.push("Proof of Address")
            }

            state.proofOfTaxResidency = tpayload
            state.proofOfWealth = proofOfWealth || []
            state.sourceOfWealth = sourceOfWealth || []
            state.otherDocuments = otherDocuments || []
            state.missingKycFields = missingFields
        },
        deleteProspectKyc: (state, action) => {
            const proofType = action.payload.field
            const deletedId = action.payload.id
            const currentList = state[proofType]
            const updatedRecord = currentList.filter(
                (rec) => rec.id !== deletedId
            )
            state[proofType] = updatedRecord
        },
        setProspectInterest: (state, action) => {
            const {
                currentMemberships,
                financialProduct,
                financialService,
                personality,
                lifestyleService,
                lifestyleProduct,
                previousVacations
            } = action.payload

            const payload = {
                currentMemberships: currentMemberships || [],
                financialService: financialService || [],
                financialProduct: financialProduct || [],
                lifestyleService: lifestyleService || [],
                lifestyleProduct: lifestyleProduct || [],
                previousVacations: previousVacations || [],
                personality: personality || []
            }

            state.interestDetails = payload
        },
        setFinancialDropdowns: (state, action) => {
            const {
                finGoalOptions,
                finProdOptions,
                indOptions,
                riskOptions,
                rmOptions
            } = action.payload
            state.dropdowns.financialGoalOptions = finGoalOptions || []
            state.dropdowns.financialProductOptions = finProdOptions || []
            state.dropdowns.industryPreferenceOptions = indOptions || []
            state.dropdowns.riskAppetiteOptions = riskOptions || ""
            state.dropdowns.rmAuthorizedOptions = rmOptions || ""
        },
        setProspectFinancialDetails: (state, action) => {
            const payload = {
                netWorth: action.payload.netWorth || "",
                sourceOfWealth: action.payload.sourceOfWealth || [],
                investibleWealth: action.payload.investibleWealth || "",
                riskAppetite: action.payload.riskAppetite?.id || "",
                industryPreference:
                    action.payload.industryPreference?.map((ip) => ip.id) || [],
                financialGoals:
                    action.payload.financialGoals?.map((fg) => fg.id) || [],
                joiningFee: action.payload.joiningFee || "",
                subscriptionFee: action.payload.subscriptionFee || "",
                isRmAuthorized: action.payload.isRmAuthorized?.id || "",
                presentAssetAllocation:
                    action.payload.presentAssetAllocation || []
            }

            const missingFields = []
            if (!action.payload.riskAppetite) {
                missingFields.push("Risk appetite")
            }
            if (!action.payload.industryPreference) {
                missingFields.push("Industry preference")
            }
            if (!action.payload.riskAppetite) {
                missingFields.push("Financial goals")
            }
            if (!action.payload.netWorth) {
                missingFields.push("Net worth")
            }

            state.missingFinancialFields = missingFields
            state.financialDetails = payload
        }
    }
})

export const {
    setPersonalDetails,
    setProfessionalDetails,
    setProspectKycDetails,
    setProspectKycDropdowns,
    deleteProspectKyc,
    setProspectInterest,
    setFinancialDropdowns,
    setProspectFinancialDetails
} = prospectProfileSlice.actions

export default prospectProfileSlice.reducer
